<template>
  <div class="queue__table">
    <div class="queue__row queue__names-row">
      <div class="queue__plate-number">
        {{ $t('queue.auto_plate_number') }}
      </div>
      <div class="queue__driver">{{ $t('queue.driver') }}</div>
      <div class="queue__type-auto-text">
        {{ $t('queue.type_of_auto') }}
      </div>
      <div class="queue__passangers-text">{{ $t('queue.passangers') }}</div>
      <div class="queue__date">{{ $t('queue.date') }}</div>
      <div class="queue__time">{{ $t('queue.time') }}</div>
    </div>

    <div class="queue__body">
      <div v-if="!queueList.length" class="queue__empty">
        <svg-icon name="truck"></svg-icon>
        <Heading level="5">{{ $t('queue.empty') }}</Heading>
      </div>
      <div
        v-for="(item, index) in queueList"
        v-else
        :key="index + 1"
        class="queue__row"
        :class="{
          active: item.status === 'to_buffer_zone',
        }"
        @click="selectAutoInQueue(item)"
      >
        <div class="queue__plate-number">
          <img
            :src="`/images/flags/${item.country_registration.iso}.svg`"
            alt=""
          />
          <span class="queue__plate-text">{{
            item.auto_registration_plate
          }}</span>
        </div>
        <div class="queue__driver">{{ item.full_name }}</div>
        <div
          class="queue__type-auto"
          :class="{
            flammable: item.type_auto === 'flammable_truck',
            perishable: item.type_auto === 'perishable_truck',
          }"
        >
          {{ $t(`queue.type_auto.${item.type_auto}`) }}
        </div>
        <div class="queue__passangers">{{ item.count_of_passengers }}</div>
        <div class="queue__date">{{ item.date }}</div>
        <div class="queue__time">{{ item.time_interval }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/elements/Heading/Heading.vue';

export default {
  components: {
    Heading,
  },
  props: {
    queueList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectAutoInQueue(auto) {
      if (auto.status === 'to_buffer_zone') {
        this.$emit('move-car', auto);
      }
      return;
    },
  },
};
</script>

<style lang="sass" scoped>
.queue__table
  display: flex
  flex-direction: column
  border-radius: var(--primary-border-radius)
  box-shadow: var(--primary-border-shadow)
  padding: 0 22px 0px 25px
  margin: 32px 0 80px
  border: 1px solid var(--primary-color-dark-grey-2)

.queue__body
  position: relative

.queue__row
  position: relative
  display: grid
  grid-template-columns: 14% 18% 17% 8% 10% 12%
  align-items: center
  height: 72px
  gap: 45px
  font-size: 18px
  font-weight: 600
  border-bottom: 1px solid var(--primary-color-dark-grey-2)
  color: var(--primary-color-dark-blue)

  @media screen and (max-width: 1199px)
    grid-template-columns: 16% 18% 19% 8% 10% 13%
    gap: 27px
    font-size: 16px

.queue__row.active
  background-color: rgba(9, 163, 86, 0.04)
  padding: 1px 22px 0 25px
  margin: -1px -22px 0 -25px
  border-top: 1px solid var(--primary-color-success)
  border-bottom: 1px solid var(--primary-color-success)
  cursor: pointer

.queue__row:last-child
  border-bottom: none

.queue__names-row
  height: 56px
  font-weight: normal
  font-size: 16px
  color: rgba(40, 59, 89, 0.5)

  @media screen and (max-width: 1080px)
    font-size: 14px

.queue__plate-number
  display: flex
  align-items: center

  img
    width: 24px
    height: 16px

.queue__plate-text
  margin-left: 12px

.queue__type-auto
  display: flex
  align-items: center
  justify-content: center
  height: 38px
  width: 100%
  background-color: rgba(40, 59, 89, 0.06)

.queue__type-auto.flammable
  background-color: rgba(255, 0, 0, 0.06)
  color: var(--primary-color-error)

.queue__type-auto.perishable
  background-color: rgba(255, 121, 23, 0.06)
  color: var(--primary-color-wasty)

.queue__empty
  min-height: 550px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.queue__empty .title
  color: var(--primary-color-dark-blue)
  font-weight: 500
  margin-top: 24px
  margin-bottom: 0

.icon-truck
  position: relative
  left: 16px
  width: 203px
  height: 100px
</style>
