<template>
  <div class="input-count-box">
    <ButtonBase
      color="white"
      size="sm"
      :disabled="isDecrementBtnDisabled"
      is-count-btn
      @click.native="decrementCount"
    >
      <svg-icon name="btn-arrow-right"></svg-icon>
    </ButtonBase>
    <TextField
      id="passengers-count"
      v-model="count"
      type="number"
      name="passengers-count"
      class="input-count"
      required
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <ButtonBase
      color="white"
      size="sm"
      :disabled="isIncrementBtnDisabled"
      is-count-btn
      @click.native="incrementCount"
    >
      <svg-icon name="btn-arrow-left"></svg-icon>
    </ButtonBase>
    <InputError
      v-if="error"
      :error-txt="errorTxt"
      is-show-icon
      :class="{
        'error-message--autos': isAutoCount,
      }"
    />
  </div>
</template>

<script>
import TextField from '@/elements/Inputs/TextField.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import InputError from '@/elements/Errors/InputError.vue';

export default {
  components: {
    TextField,
    ButtonBase,
    InputError,
  },
  props: {
    value: {
      type: [Number, String],
      default: 1,
    },
    isAutoCount: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: '',
    },
  },
  computed: {
    isDecrementBtnDisabled() {
      return this.count <= 1;
    },
    isIncrementBtnDisabled() {
      return this.isAutoCount && this.count >= 5;
    },
    count: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    decrementCount() {
      this.$emit('decrement');
    },
    incrementCount() {
      this.$emit('increment');
    },
  },
};
</script>

<style lang="sass">
.input-count-box
  display: flex
  align-items: center
  justify-content: space-between
  position: relative

.input-count-box .input-box
  margin: 0 32px

.input-count-box .input
  font-weight: 500
  font-size: 24px
  border: 1px solid var(--primary-color-dark-grey-2)
  color: var(--primary-color-dark-blue)
  text-align: center

.input-count-box .primary-btn
  flex: 0 0 64px
  transition: none

.input-count-box .primary-btn:hover
  border: 1px solid var(--primary-color-dark-grey-3)

.input-count-box .primary-btn:active
  border: 2px solid var(--btn-color-blue)

.input-count-box .primary-btn[disabled]:hover
  border: 1px solid var(--primary-color-dark-grey-1)

.input-count-box .error-message--autos
  bottom: 12px
</style>
