<template>
  <div class="queue__pagination">
    <ButtonBase
      color="white"
      size="sm"
      :disabled="isDispatchCarBtnDisabled"
      class="queue__first-line"
      @click.native="$emit('dispatch-car')"
    >
      <svg-icon name="next-queue" class="queue__next-queue"></svg-icon>
    </ButtonBase>
    <ButtonBase
      color="white"
      size="sm"
      :disabled="isPrevBtnDisabled"
      @click.native="$emit('go-prev-page')"
    >
      <svg-icon name="btn-arrow-right"></svg-icon>
    </ButtonBase>
    <ButtonBase
      color="white"
      size="sm"
      :disabled="isNextBtnDisabled"
      @click.native="$emit('go-next-page')"
    >
      <svg-icon name="btn-arrow-left"></svg-icon>
    </ButtonBase>
  </div>
</template>

<script>
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

export default {
  components: {
    ButtonBase,
  },
  props: {
    isDispatchCarBtnDisabled: {
      type: Boolean,
      default: false,
    },
    isPrevBtnDisabled: {
      type: Boolean,
      default: false,
    },
    isNextBtnDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.queue__pagination
  position: absolute
  right: 0
  top: -90px
  display: flex
  gap: 16px
  margin-bottom: 24px

.queue__first-line
  margin-right: 16px

.queue__next-queue
  width: 28px
  height: 33px
</style>
