<template>
  <div class="queue">
    <div v-if="queueList" class="queue__inner">
      <QueuePagination
        :is-dispatch-car-btn-disabled="!queueList.length"
        :is-prev-btn-disabled="!prevPage"
        :is-next-btn-disabled="!nextPage"
        @dispatch-car="openModalManualCarDispatch"
        @go-prev-page="goToPrevPage"
        @go-next-page="goToNextPage"
      />
      <QueueTable :queue-list="formattedQueueList" @move-car="moveCarHandler" />
    </div>
    <Loader v-else />

    <Popup :dialog.sync="modalManualCarDispatch">
      <div class="queue__modal">
        <Heading level="4" class="queue__modal-title">{{
          $t('queue.quick_skip')
        }}</Heading>
        <div class="queue__modal-text">
          {{ $t('queue.how_much_to_skip_auto') }}
        </div>
        <div class="queue__modal-buttons-row">
          <ButtonBase
            v-for="item in 5"
            :key="item"
            size="sm"
            class="queue__modal-button"
            :class="{ active: autosCount === item }"
            color="white"
            @click.native="selectAutosCount(item)"
            >{{ item }}
          </ButtonBase>
        </div>
        <CountField
          v-model.number="autosCount"
          class="queue__modal-count-field"
          is-auto-count
          :error="
            ($v.autosCount.$dirty && !$v.autosCount.required) ||
            ($v.autosCount.$dirty && $v.autosCount.$invalid)
          "
          :error-txt="autosCountErrorText"
          @decrement="decrementCount"
          @increment="incrementCount"
        />
        <div class="queue__modal-buttons-wrapper">
          <ButtonBase
            size="md-bg"
            color="white"
            @click.native="closeModalManualCarDispatch"
          >
            {{ $t('queue.cancel') }}
          </ButtonBase>
          <ButtonBase
            size="md-bg"
            color="blue"
            @click.native="dispatchCarsHandler"
          >
            <template v-if="!loadingAcceptDispatchBtn">
              {{ $t('queue.confirm') }}
            </template>
            <Loader v-else color="white" size="sm" />
          </ButtonBase>
        </div>
      </div>
    </Popup>
    <Popup
      :dialog.sync="modalMoveCar"
      is-persistent
      custom-class="v-dialog--wide"
    >
      <div class="queue__modal-change-auto-in-queue change-auto">
        <Heading level="4" class="queue__modal-change-auto-in-queue-title">{{
          $t('queue.choose_action_on_car')
        }}</Heading>

        <SelectedAuto :auto-info="selectedAuto"></SelectedAuto>

        <div class="change-auto__buttons-wrapper">
          <ButtonBase
            size="md-bg"
            color="white"
            class="replace-btn"
            @click.native="replaceCarHandler"
          >
            <template v-if="!loadingReplaceCarBtn">
              <svg-icon name="replace"></svg-icon>
              <span>
                {{ $t('queue.replace') }}
              </span>
            </template>
            <Loader v-else color="blue" size="sm" />
          </ButtonBase>
          <ButtonBase
            size="md-bg"
            color="blue"
            @click.native="releaseCarHandler"
          >
            <template v-if="!loadingReleaseCarBtn">
              <svg-icon name="release"></svg-icon>
              <span>
                {{ $t('queue.release') }}
              </span>
            </template>
            <Loader v-else color="white" size="sm" />
          </ButtonBase>
        </div>
        <button
          type="button"
          class="queue__modal-close-btn"
          @click="closeModalMoveCar"
        >
          <svg-icon name="popup-close"></svg-icon>
        </button>
      </div>
    </Popup>
  </div>
</template>

<script>
import { required, between, numeric } from 'vuelidate/lib/validators';

import { getDateFromString } from '@/library/dateFormat';
import QueuePagination from '@/components/Queue/QueuePagination.vue';
import QueueTable from '@/components/Queue/QueueTable.vue';
import SelectedAuto from '@/components/Queue/QueueSelectedAuto.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Popup from '@/elements/Popup/Popup.vue';
import Heading from '@/elements/Heading/Heading.vue';
import CountField from '@/elements/Inputs/CountField.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    QueuePagination,
    QueueTable,
    SelectedAuto,
    ButtonBase,
    Popup,
    Heading,
    CountField,
    Loader,
  },
  validations: {
    autosCount: {
      required,
      numeric,
      between: between(1, 5),
    },
  },
  data() {
    return {
      queueList: null,
      tablePage: 1,
      modalMoveCar: false,
      modalManualCarDispatch: false,
      autosCount: 1,
      timerId: null,
      prevPage: false,
      nextPage: false,
      loadingAcceptDispatchBtn: false,
      loadingReplaceCarBtn: false,
      loadingReleaseCarBtn: false,
      selectedAuto: null,
    };
  },
  computed: {
    formattedQueueList() {
      return this.queueList.length
        ? this.queueList.map((item) => {
            const formattedFirstName = `${item.first_name.slice(0, 1)}.`;
            const formattedSecondName = item.second_name
              ? ` ${item.second_name.slice(0, 1)}.`
              : '';
            const formattedDate = getDateFromString(item.datetime).date;

            return {
              ...item,
              full_name: `${item.last_name} ${formattedFirstName}${formattedSecondName}`,
              date: formattedDate,
            };
          })
        : [];
    },
    autosCountErrorText() {
      if (!this.$v.autosCount.required) {
        return this.$t('input_validation.passengers_count.empty');
      }
      return this.$t('input_validation.passengers_count.cars_dispatch');
    },
  },
  watch: {
    modalManualCarDispatch(value) {
      if (!value) {
        this.resetAutosCount();
      }
    },
  },
  mounted() {
    this.timerId = setInterval(async () => {
      const res = await this.$store.dispatch('getQueueList', this.tablePage);
      this.queueList = res.data;
      this.prevPage = !!res.prev_page_url;
      this.nextPage = !!res.next_page_url;
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timerId);
  },
  methods: {
    async getCarTicketByUuid(uuid) {
      this.resetSelectedAuto();

      const res = await this.$store.dispatch('getCarTicketByUuid', uuid);
      this.selectedAuto = res;
    },
    resetSelectedAuto() {
      if (this.selectedAuto) {
        this.selectedAuto = null;
      }
      return;
    },
    moveCarHandler(auto) {
      this.openModalMoveCar();
      this.getCarTicketByUuid(auto.uuid);
    },
    openModalMoveCar() {
      this.modalMoveCar = true;
    },

    closeModalMoveCar() {
      this.modalMoveCar = false;
    },
    openModalManualCarDispatch() {
      this.modalManualCarDispatch = true;
    },
    closeModalManualCarDispatch() {
      this.modalManualCarDispatch = false;
    },
    resetAutosCount() {
      this.autosCount = 1;
    },
    clearQueueList() {
      this.queueList = null;
    },
    goToPrevPage() {
      this.tablePage--;
      this.clearQueueList();
    },
    goToNextPage() {
      this.tablePage++;
      this.clearQueueList();
    },
    decrementCount() {
      this.autosCount--;
    },
    incrementCount() {
      if (this.autosCount <= 4) {
        this.autosCount++;
      }
    },
    selectAutosCount(item) {
      this.autosCount = item;
    },
    async dispatchCarsHandler() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loadingAcceptDispatchBtn = true;
      await this.$store.dispatch('manualCarDispatch', this.autosCount);
      this.loadingAcceptDispatchBtn = false;

      this.closeModalManualCarDispatch();
      this.resetAutosCount();
    },
    async replaceCarHandler() {
      this.loadingReplaceCarBtn = true;
      await this.$store.dispatch('replaceCarInQueue', this.selectedAuto.uuid);
      this.loadingReplaceCarBtn = false;

      this.closeModalMoveCar();
    },
    async releaseCarHandler() {
      this.loadingReleaseCarBtn = true;
      await this.$store.dispatch('releaseCarFromQueue', this.selectedAuto.uuid);
      this.loadingReleaseCarBtn = false;

      this.closeModalMoveCar();
    },
  },
};
</script>
<style lang="sass" scoped>
.queue
  position: relative

.queue__modal,
.queue__modal-change-auto-in-queue
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center

.queue__modal-title
  margin-top: 0
  margin-bottom: 24px !important

.queue__modal-text
  width: 400px
  font-size: 24px
  line-height: 32px
  margin-bottom: 32px

.queue__modal-buttons-row,
.queue__modal-buttons-wrapper,
.change-auto__buttons-wrapper
  display: flex
  gap: 32px

.change-auto__buttons-wrapper .primary-btn span
  margin-left: 7px

.change-auto__buttons-wrapper .replace-btn
  background-color: rgba(62, 85, 236, 0.06)
  border: 1px solid var(--btn-color-blue)

.queue__modal-button.active
  background: var(--btn-color-blue)
  color: var(--primary-color-white)

.queue__modal-count-field
  width: 448px
  margin-top: 32px
  margin-bottom: 48px
  padding: 32px 0
  border-top: 1px solid var(--primary-color-light-grey-2)
  border-bottom: 1px solid var(--primary-color-light-grey-2)

.queue__modal-change-auto-in-queue-title
  width: 430px
  margin: 0 0 24px !important

.queue__modal-close-btn
  width: 60px
  height: 60px
  border-radius: var(--primary-border-radius)
  border: 1px solid rgb(40 59 89 / 50%)
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  top: 30px
  right: 30px

  @media screen and (max-width: 1199px)
    top: 50px
    right: 50px

.icon-replace
  width: 23px
  height: 14px

.icon-release
  width: 13px
  height: 12px
</style>
